import React from 'react';
import { observer } from 'mobx-react-lite';

import { useExternalState } from '../ExternalStateProvider';

import styles from '../login/Login.module.scss';
import Button, { buttonSizes, buttonTypes } from '../../components/form/Button';
import TextInputControl, { inputTypes } from '../../components/control_components/TextInputControl';

const ResetPassword = observer(function ResetPasswordComponent() {
    const externalState = useExternalState();

    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');

    const [error, setError] = React.useState(undefined);
    const [beginMatchValidation, setBeginMatchValidation] = React.useState(false);
    const noMatchError = 'Passwords do not match.';

    function handlePasswordChange(value) {
        setPassword(value);
        if (beginMatchValidation) {
            validateMatch(value, confirmPassword);
        }
    }

    function handleConfirmPasswordChange(value) {
        setConfirmPassword(value);
        if (beginMatchValidation) {
            validateMatch(value, password);
        }
    }

    function validateMatch(valueToCheck, valueToMatch) {
        if (valueToCheck === valueToMatch) {
            setError(undefined);
            return true;
        }
        setBeginMatchValidation(true);
        setError(noMatchError);
        return false;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        if (validateMatch(password, confirmPassword)) {
            await externalState.submitResetPassword(password, confirmPassword);
        }
    }

    function handleForgotClick(event) {
        event.preventDefault();
        externalState.navigateToRequestReset();
    }

    return (
        <section className={styles.loginWrapper}>
            <div className={styles.loginContainer}>
                <h3 className={styles.loginHeadline}>Reset Password</h3>
                <h4 className={styles.loginSubheadline}>Enter your new password.</h4>
                <form className={styles.loginForm} onSubmit={(event) => handleSubmit(event)}>
                    <div className={styles.fieldsContainer}>
                        <TextInputControl
                            type={inputTypes.PASSWORD}
                            placeholder="New Password"
                            onChange={(value) => handlePasswordChange(value)}
                        />
                        <TextInputControl
                            type={inputTypes.PASSWORD}
                            placeholder="Confirm New Password"
                            onChange={(value) => handleConfirmPasswordChange(value)}
                        />

                        {error && <p className={styles.errorMessage}>{error}</p>}
                        {externalState.errorMessage && (
                            <p className={styles.errorMessage}>{externalState.errorMessage}</p>
                        )}
                    </div>
                    <div className={styles.loginSubmitSection}>
                        <Button
                            text="Change Password"
                            size={buttonSizes.SMALL}
                            type={buttonTypes.SUBMIT}
                            disabled={!!externalState.errorMessage}
                        />
                    </div>
                </form>
                {externalState.errorMessage && (
                    <div className={styles.loginForgotPassword}>
                        <button type="button" onClick={(event) => handleForgotClick(event)}>
                            Request a new reset code...
                        </button>
                    </div>
                )}
            </div>
        </section>
    );
});

ResetPassword.propTypes = {};

export default ResetPassword;
