import React from 'react';
import { observer } from 'mobx-react-lite';

import { useExternalState } from '../ExternalStateProvider';

import styles from './Login.module.scss';
import Button, { buttonSizes, buttonTypes } from '../../components/form/Button';
import TextInputControl, { inputTypes } from '../../components/control_components/TextInputControl';

const Reset = observer(function ResetComponent() {
    const externalState = useExternalState();
    const [user, setUser] = React.useState({});

    function handleChange(value) {
        setUser({ ...user, email: value });
    }

    function handleLoginClick(event) {
        event.preventDefault();
        externalState.navigateToLogin();
    }

    async function handleSubmit(event) {
        event.preventDefault();
        await externalState.submitReset(user);
    }

    return (
        <section className={styles.loginWrapper}>
            <div className={styles.loginContainer}>
                <h3 className={styles.loginHeadline}>Reset Password</h3>

                {externalState.successMessage ? (
                    <h4 className={styles.successMessage}>{externalState.successMessage}</h4>
                ) : (
                    <h4 className={styles.loginSubheadline}>
                        Enter your email address. We will send you a link to reset your password.
                    </h4>
                )}

                <form className={styles.loginForm} onSubmit={(event) => handleSubmit(event)}>
                    <div className={styles.fieldsContainer}>
                        <TextInputControl
                            type={inputTypes.EMAIL}
                            placeholder="Email address"
                            onChange={handleChange}
                        />
                        {externalState.errorMessage && (
                            <p className={styles.errorMessage}>{externalState.errorMessage}</p>
                        )}
                    </div>
                    <div className={styles.loginSubmitSection}>
                        <Button
                            text={externalState.successMessage ? 'Resend' : 'Send Reset Email'}
                            size={buttonSizes.SMALL}
                            type={buttonTypes.SUBMIT}
                        />
                    </div>
                </form>
                <div className={styles.loginForgotPassword}>
                    <button type="button" onClick={(event) => handleLoginClick(event)}>
                        Back to Login...
                    </button>
                </div>
            </div>
        </section>
    );
});

export default Reset;
