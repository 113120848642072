import React from 'react';
import { observer } from 'mobx-react-lite';

import { useExternalState } from '../ExternalStateProvider';

import styles from './Login.module.scss';
import Button, { buttonSizes, buttonTypes } from '../../components/form/Button';
import TextInputControl, { inputTypes } from '../../components/control_components/TextInputControl';

const Login = observer(function LoginComponent() {
    const externalState = useExternalState();
    const [user, setUser] = React.useState({});

    function handleChange(field, value) {
        setUser({ ...user, [field]: value });
    }

    function handleForgotClick(event) {
        event.preventDefault();
        externalState.navigateToRequestReset();
    }

    async function handleSubmit(event) {
        event.preventDefault();
        await externalState.submitLogin(user);
    }

    return (
        <section className={styles.loginWrapper}>
            <div className={styles.loginContainer}>
                <h3 className={styles.loginHeadline}>SecureZone</h3>
                {externalState.successMessage && (
                    <h4 className={styles.successMessage}>{externalState.successMessage}</h4>
                )}
                <form className={styles.loginForm} onSubmit={(event) => handleSubmit(event)}>
                    <div className={styles.fieldsContainer}>
                        <TextInputControl
                            type={inputTypes.EMAIL}
                            placeholder="Email address"
                            onChange={(value) => handleChange('email', value)}
                        />
                        <TextInputControl
                            type={inputTypes.PASSWORD}
                            placeholder="Password"
                            onChange={(value) => handleChange('password', value)}
                        />
                        {externalState.errorMessage && (
                            <p className={styles.errorMessage}>{externalState.errorMessage}</p>
                        )}
                    </div>

                    <div className={styles.loginSubmitSection}>
                        <Button text="Sign In" size={buttonSizes.SMALL} type={buttonTypes.SUBMIT} />
                    </div>
                </form>
                <div className={styles.loginForgotPassword}>
                    <button type="button" onClick={(event) => handleForgotClick(event)}>
                        Forgot your password...
                    </button>
                </div>
            </div>
        </section>
    );
});

export default Login;
