import React from 'react';
import { observer } from 'mobx-react-lite';
import ExternalState, { externalPages } from './state/external_state';
import { ExternalStateProvider } from './ExternalStateProvider';

import styles from './App.module.scss';
import Login from './login/Login';
import RequestReset from './login/RequestReset';
import ResetPassword from './validate/ResetPassword';
import ValidateEmail from './validate/ValidateEmail';
import Invitation from './invitation/Invitation';
import UserInvitation from './invitation/UserInvitation';

const externalState = new ExternalState();
externalState.launchExternalApp();

const App = observer(function AppComponent() {
    return (
        <ExternalStateProvider state={externalState}>
            <section className={styles.externalWrapper}>
                {externalState.currentPage === externalPages.LOGIN && <Login />}
                {externalState.currentPage === externalPages.REQUEST_RESET && <RequestReset />}
                {externalState.currentPage === externalPages.RESET_PASSWORD && <ResetPassword />}
                {externalState.currentPage === externalPages.VALIDATE_EMAIL && <ValidateEmail />}
                {externalState.currentPage === externalPages.INVITATION && <Invitation />}
                {externalState.currentPage === externalPages.USER_INVITATION && <UserInvitation />}
            </section>
        </ExternalStateProvider>
    );
});

export default App;
