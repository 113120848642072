import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useExternalState } from '../ExternalStateProvider';

import styles from '../login/Login.module.scss';
import Button, { buttonSizes, buttonTypes } from '../../components/form/Button';
import TextLink from '../../components/TextLink';

const UserInvitation = observer(function UserInvitationComponent() {
    const externalState = useExternalState();
    const [invitationAccepted, setInvitationAccepted] = useState(false);

    async function handleSubmit(event) {
        event.preventDefault();
        await externalState.acceptUserInvitation();
        setInvitationAccepted(true);
    }

    return (
        <section className={styles.loginWrapper}>
            <div className={styles.loginContainer}>
                <h3 className={styles.loginHeadline}>Accept Invitation</h3>
                {invitationAccepted && (
                    <h4 className={styles.successMessage}>Invitation Accepted</h4>
                )}
                <form className={styles.loginForm} onSubmit={(event) => handleSubmit(event)}>
                    <div className={styles.loginSubmitSection}>
                        {invitationAccepted ? (
                            <TextLink onClick={() => (window.location.href = '/')}>
                                Log in to SecureZone
                            </TextLink>
                        ) : (
                            <Button
                                text="Accept Invitation"
                                size={buttonSizes.SMALL}
                                type={buttonTypes.SUBMIT}
                            />
                        )}
                    </div>
                </form>
            </div>
        </section>
    );
});

UserInvitation.propTypes = {};

export default UserInvitation;
