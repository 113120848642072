import React from 'react';
import PropTypes from 'prop-types';
import ExternalState from './state/external_state';

const ExternalStateContext = React.createContext();

export function ExternalStateProvider({ children, state }) {
    return <ExternalStateContext.Provider value={state}>{children}</ExternalStateContext.Provider>;
}
ExternalStateProvider.propTypes = {
    children: PropTypes.element.isRequired,
    state: PropTypes.instanceOf(ExternalState).isRequired,
};

export const useExternalState = () => React.useContext(ExternalStateContext);
