import React from 'react';

import { useExternalState } from '../ExternalStateProvider';

import styles from '../login/Login.module.scss';
import Button, { buttonSizes, buttonTypes } from '../../components/form/Button';
import TextInputControl, { inputTypes } from '../../components/control_components/TextInputControl';

export default function ValidateEmail() {
    const externalState = useExternalState();
    const [password, setPassword] = React.useState({});

    function handleChange(value) {
        setPassword(value);
    }

    async function handleSubmit(event) {
        event.preventDefault();
        await externalState.submitValidateEmail(password);
    }

    return (
        <section className={styles.loginWrapper}>
            <div className={styles.loginContainer}>
                <h3 className={styles.loginHeadline}>Validate Email</h3>
                <h4 className={styles.loginSubheadline}>
                    Enter your password to complete your validation.
                </h4>
                <form className={styles.loginForm} onSubmit={(event) => handleSubmit(event)}>
                    <div className={styles.fieldsContainer}>
                        <TextInputControl
                            type={inputTypes.PASSWORD}
                            placeholder="Password"
                            onChange={(value) => handleChange(value)}
                        />
                        {externalState.errorMessage && (
                            <p className={styles.errorMessage}>{externalState.errorMessage}</p>
                        )}
                    </div>
                    <div className={styles.loginSubmitSection}>
                        <Button
                            text="Validate Email"
                            size={buttonSizes.SMALL}
                            type={buttonTypes.SUBMIT}
                        />
                    </div>
                </form>
            </div>
        </section>
    );
}
