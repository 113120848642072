import axios from 'axios';
import { makeAutoObservable } from 'mobx';
import {
    submitAcceptInvitation,
    submitAcceptUserInvitation,
} from '../../entities/sentry/invitation';

export const externalPages = {
    LOGIN: 'login',
    REQUEST_RESET: 'request_reset',
    RESET_PASSWORD: 'reset_password',
    VALIDATE_EMAIL: 'validate_email',
    INVITATION: 'invitations',
    USER_INVITATION: 'user_invitation',
};

export default class ExternalState {
    constructor() {
        this.currentPage = externalPages.LOGIN;
        this.resetCode = undefined;
        this.errorMessage = undefined;
        this.successMessage = undefined;
        this.validationCode = undefined;
        this.invitationToken = undefined;

        makeAutoObservable(this, {
            submitLogin: false,
            submitReset: false,
            submitResetPassword: false,
        });

        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
    }

    setErrorMessage(message) {
        this.errorMessage = message;
    }

    setSuccessMessage(message) {
        this.successMessage = message;
    }

    navigateToLogin() {
        this.currentPage = externalPages.LOGIN;
    }

    navigateToRequestReset() {
        this.setErrorMessage(undefined);
        this.setSuccessMessage(undefined);
        this.currentPage = externalPages.REQUEST_RESET;
    }

    navigateToResetPassword(resetCode) {
        this.resetCode = resetCode;
        this.currentPage = externalPages.RESET_PASSWORD;
    }

    navigateToValidateEmail(validationCode) {
        this.validationCode = validationCode;
        this.currentPage = externalPages.VALIDATE_EMAIL;
    }

    navigateToAcceptInvitation(invitationToken) {
        this.invitationToken = invitationToken;
        this.currentPage = externalPages.INVITATION;
    }

    navigateToUserInvitation(invitationToken) {
        this.invitationToken = invitationToken;
        this.currentPage = externalPages.USER_INVITATION;
    }

    launchExternalApp() {
        this.navigateToLocationHash();
    }

    navigateToLocationHash() {
        const { pathname } = window.location;

        const [, page, param] = pathname.split('/');
        if (page) {
            switch (page) {
                case externalPages.REQUEST_RESET:
                    this.navigateToRequestReset();
                    break;
                case externalPages.RESET_PASSWORD:
                    this.navigateToResetPassword(param);
                    break;
                case externalPages.VALIDATE_EMAIL:
                    this.navigateToValidateEmail(param);
                    break;
                case externalPages.INVITATION:
                    this.navigateToAcceptInvitation(param);
                    break;
                case externalPages.USER_INVITATION:
                    this.navigateToUserInvitation(param);
                    break;
                default:
                    this.navigateToLogin();
            }
        } else {
            this.navigateToLogin();
        }
    }

    async submitLogin(user) {
        await axios
            .post(
                '/submit_login',
                {
                    user: {
                        email: user.email,
                        password: user.password,
                    },
                },
                {
                    headers: {
                        Accept: 'application/json',
                    },
                },
            )
            .then(() => {
                window.location = '/';
            })
            .catch(() => {
                this.setErrorMessage('Invalid email or password.');
            });
    }

    async submitReset(user) {
        await axios
            .post(
                '/submit_request_reset',
                {
                    email: user.email,
                },
                {
                    headers: {
                        Accept: 'application/json',
                    },
                },
            )
            .then(() => {
                this.setSuccessMessage('Password reset instructions sent to your email.');
            })
            .catch(() => {
                this.setErrorMessage('Unable to send reset instructions.');
            });
    }

    async submitResetPassword(password, confirmPassword) {
        await axios
            .put(
                '/submit_reset_password',
                {
                    user: {
                        reset_code: this.resetCode,
                        password: password,
                        password_confirmation: confirmPassword,
                    },
                },
                {
                    headers: {
                        Accept: 'application/json',
                    },
                },
            )
            .then(() => {
                this.setSuccessMessage('Password successfully reset. Login to continue.');
                this.navigateToLogin();
            })
            .catch(() => {
                this.setErrorMessage('Invalid reset code.');
            });
    }

    async submitValidateEmail(password) {
        await axios
            .post(
                '/submit_validate_email',
                {
                    validation_code: this.validationCode,
                    password: password,
                },
                {
                    headers: {
                        Accept: 'application/json',
                    },
                },
            )
            .then(() => {
                window.location = '/';
            })
            .catch(() => {
                this.setErrorMessage('Invalid validation code or password.');
            });
    }

    async acceptNewInvitation(firstName, lastName, password, confirmPassword) {
        await submitAcceptInvitation(
            this.invitationToken,
            firstName,
            lastName,
            password,
            confirmPassword,
        )
            .then(() => {
                this.setSuccessMessage('Password successfully reset. Login to continue.');
                this.navigateToLogin();
            })
            .catch(() => {
                this.setErrorMessage('Invalid invitation token.');
            });
    }

    async acceptUserInvitation(password) {
        await submitAcceptUserInvitation(this.invitationToken, password)
            .then(() => {
                window.location = '/';
            })
            .catch(() => {
                this.setErrorMessage('Invalid invitation token.');
            });
    }
}
