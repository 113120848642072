import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useExternalState } from '../ExternalStateProvider';

import styles from '../login/Login.module.scss';
import Button, { buttonSizes, buttonTypes } from '../../components/form/Button';
import TextInputControl, { inputTypes } from '../../components/control_components/TextInputControl';
import StatusMessage from '../../components/control_elements/StatusMessage';

const Invitation = observer(function InvitationComponent() {
    const externalState = useExternalState();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [showNameError, setShowNameError] = useState(false);
    const [showPasswordError, setPasswordError] = useState(false);

    function handleFirstNameChange(value) {
        if (showNameError && value !== '' && lastName !== '') {
            setShowNameError(false);
        }
        setFirstName(value);
    }

    function handleLastNameChange(value) {
        if (showNameError && value !== '' && firstName !== '') {
            setShowNameError(false);
        }
        setLastName(value);
    }

    function handlePasswordChange(value) {
        if (showPasswordError && value !== '' && value === confirmPassword) {
            setPasswordError(false);
        }
        setPassword(value);
    }

    function handleConfirmPasswordChange(value) {
        if (showPasswordError && value !== '' && value === password) {
            setPasswordError(false);
        }
        setConfirmPassword(value);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        if (firstName === '' || lastName === '') {
            setShowNameError(true);
            return;
        }

        if (password === '' || confirmPassword === '' || password !== confirmPassword) {
            setPasswordError(true);
            return;
        }

        await externalState.acceptNewInvitation(firstName, lastName, password, confirmPassword);
    }

    return (
        <section className={styles.loginWrapper}>
            <div className={styles.loginContainer}>
                <h3 className={styles.loginHeadline}>Create Account</h3>
                <h4 className={styles.loginSubheadline}>
                    Enter your name and create a password to make your account.
                </h4>
                <form className={styles.loginForm} onSubmit={(event) => handleSubmit(event)}>
                    <div className={styles.fieldsContainer}>
                        <TextInputControl
                            type={inputTypes.TEXT}
                            placeholder="First Name"
                            value={firstName}
                            onChange={(value) => handleFirstNameChange(value)}
                        />
                        <TextInputControl
                            type={inputTypes.TEXT}
                            placeholder="Last Name"
                            value={lastName}
                            onChange={(value) => handleLastNameChange(value)}
                        />
                        {showNameError && (
                            <StatusMessage message="First and last name are required." />
                        )}
                    </div>
                    <div className={styles.fieldsContainer}>
                        <TextInputControl
                            type={inputTypes.PASSWORD}
                            placeholder="Password"
                            onChange={(value) => handlePasswordChange(value)}
                        />
                        <TextInputControl
                            type={inputTypes.PASSWORD}
                            placeholder="Confirm Password"
                            onChange={(value) => handleConfirmPasswordChange(value)}
                        />
                        {showPasswordError && (
                            <StatusMessage message="Password and Confirm Password must match." />
                        )}
                        {externalState.errorMessage && (
                            <StatusMessage message={externalState.errorMessage} />
                        )}
                    </div>
                    <div className={styles.loginSubmitSection}>
                        <Button
                            text="Create Account"
                            size={buttonSizes.SMALL}
                            type={buttonTypes.SUBMIT}
                            disabled={showNameError || showPasswordError}
                        />
                    </div>
                </form>
            </div>
        </section>
    );
});

Invitation.propTypes = {};

export default Invitation;
